import NPLButton, { BTN_HIERARCHY } from '@/components/npl/NPLButton';
import { t } from '@/utility/localization';
import React from 'react';

function MobileCTAFooter({
  setIsSignUpModalOpened,
  discountPercentageForHighestInterval,
  priceTagText,
  isPendingApproval
}) {
  return (
    <div className="fixed bottom-0 left-0 z-over-intercom w-full animate-moveUpLibrary border-t-1 border-npl-neutral-light-solid-4 bg-white-default p-12 transition-all md:hidden">
      <div className="flex items-center justify-between">
        <div>
          {priceTagText}
          {discountPercentageForHighestInterval && (
            <div className="mt-2 text-label-sm text-npl-yellow-light-solid-11">
              {discountPercentageForHighestInterval.text}
            </div>
          )}
        </div>

        <NPLButton
          hierarchy={BTN_HIERARCHY.ACCENT_PRIMARY}
          buttonText={
            isPendingApproval ? t('pending-approval') : t('join')
          }
          disabled={isPendingApproval}
          size="lg"
          onClick={() => setIsSignUpModalOpened(true)}
        />
      </div>
    </div>
  );
}

export default MobileCTAFooter;
