import { default as classNames, default as classnames } from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { useOutsideMultipleComponentsClickDetect } from '@/hooks/useOutsideComponentClickDetect';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { useMemo } from 'react';
import SearchBox from '@/pages/portal/components/SearchBox/SearchBox';
import { t } from '@/utility/localization';

/**
 * Headless dropdown component that only contains the logic to display or close the dropdown
 * @param options List of options to be displayed, it should be an array of objects with the following structure: {value: string, label: string}
 * @param value The currently selected value
 * @param onChange The callback function to be called when the value is changed
 * @param placeholder The placeholder text to be displayed when no value is selected
 * @param renderField The rendering logic for the select trigger, it exposes API of {option, placeholder , isOpen}
 * @param renderOption The rendering logic for the select option, it exposes API of {value, option}
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const HeadlessSelect = ({
  options,
  value,
  onChange,
  placeholder,
  placement,
  renderField,
  headerText,
  renderEyeBrow,
  renderOption,
  renderNoOptionsItem,
  renderChin,
  className,
  dropdownClassNames,
  customPopperClassNames,
  customWidth = false,
  alignDropdownWidth = false,
  disabled = false,
  dropdownOpenByDefault = false,
  setWidthToParent = false,
  showSearch
}) => {
  const [isOpen, setIsOpen] = useState(dropdownOpenByDefault);
  const [search, setSearch] = useState('');
  const fieldRef = useRef(null);
  const parentRef = useRef(null);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const listContainerRef = useRef(null);

  const modifiers = useMemo(() => {
    const mods = [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 4]
        }
      }
    ];
    if (setWidthToParent) {
      mods.push({
        name: 'sameWidth',
        enabled: true,
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        phase: 'beforeWrite',
        requires: ['computeStyles']
      });
    }
    return mods;
  }, []);

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement,
      modifiers
    }
  );

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSearch('');
  };

  const toggleModal = (event) => {
    if (disabled) return;
    event.stopPropagation(); // Stop event from propagating to parent elements
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    onChange(option.value);
    closeModal();
  };

  useEffect(() => {
    if (listContainerRef?.current && isOpen) {
      const selectedList = listContainerRef.current.querySelector([
        '[data-selected="true"]'
      ]);
      // the calculation below will make the selected element visible on the list and centered
      listContainerRef.current.scrollTop =
        selectedList?.offsetTop +
        selectedList?.getBoundingClientRect().height / 2 -
        listContainerRef.current.getBoundingClientRect().height / 2;
    }
  }, [isOpen, listContainerRef]);

  useOutsideMultipleComponentsClickDetect(
    [
      parentRef,
      {
        current: popperElement
      }
    ],
    closeModal
  );

  const queriedOptions = useMemo(() => {
    if (search) {
      return options?.filter((option) =>
        option?.label?.toLowerCase().includes(search.toLowerCase())
      );
    }
    return options;
  }, [options, search]);

  return (
    <div className={classnames('relative', className)} ref={parentRef}>
      <div
        role="button"
        tabIndex={0}
        onClick={toggleModal}
        ref={setReferenceElement}
        className={classNames('cursor-pointer select-none rounded-12', {
          'cursor-not-allowed': disabled,
          'pointer-events-none': disabled,
          'bg-npl-neutral-light-solid-2': disabled
        })}>
        {renderField({
          option: options?.find((o) => o.value === value),
          placeholder,
          isOpen,
          openModal,
          closeModal
        })}
      </div>
      {isOpen && (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={classnames(
              'fixed left-0 top-0 z-10 flex h-full items-center justify-center bg-white-default bg-opacity-0',
              { 'w-full': !customWidth },
              customPopperClassNames
            )}
            onClick={closeModal}></div>
          {ReactDOM.createPortal(
            <div
              className="z-over-intercom"
              ref={setPopperElement}
              {...attributes.popper}
              style={{
                top: fieldRef?.current?.getBoundingClientRect().height,
                ...styles.popper,
                width: alignDropdownWidth
                  ? referenceElement?.clientWidth
                  : 'auto' // Set popper width to match reference element
              }}>
              <div
                ref={listContainerRef}
                className={` z-20 max-h-[30vh] overflow-auto rounded-8 bg-white-default text-npl-text-icon-on-light-surface-secondary shadow-npl-styles-shadow-01 ${dropdownClassNames}`}>
                {showSearch && (
                  <div className="sticky top-0 w-full border-b-1 border-npl-neutral-light-solid-6 bg-white-default text-label-sm font-medium">
                    {headerText && (
                      <div className="bg-white-default px-8 py-10 text-label-sm font-medium">
                        {headerText}
                      </div>
                    )}
                    <SearchBox
                      initialValue=""
                      onChange={setSearch}
                      placeholder={t('search')}
                      customClassNames="!border-none !px-16"
                    />
                  </div>
                )}
                <ul
                  className={classnames('py-8', {
                    'w-full': !customWidth
                  })}>
                  {renderEyeBrow && (
                    <li role="menuitem">{renderEyeBrow()}</li>
                  )}
                  {queriedOptions?.map((option, index) => {
                    return (
                      <li
                        role="menuitem"
                        key={index}
                        className="cursor-pointer"
                        data-selected={option.value === value}>
                        {renderOption({
                          value,
                          option,
                          handleOptionClick,
                          openModal,
                          closeModal
                        })}
                      </li>
                    );
                  })}
                  {queriedOptions?.length === 0 && renderNoOptionsItem
                    ? renderNoOptionsItem()
                    : null}
                  {renderChin && <li role="menuitem">{renderChin()}</li>}
                </ul>
              </div>
            </div>,
            document.querySelector('body')
          )}
        </>
      )}
    </div>
  );
};

HeadlessSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  renderField: PropTypes.func,
  renderEyeBrow: PropTypes.func,
  renderChin: PropTypes.func,
  renderOption: PropTypes.func,
  className: PropTypes.string,
  placement: PropTypes.string
};

export default HeadlessSelect;
