import React, { useMemo } from 'react';
import { isValid, parse } from 'date-fns';
import classNames from 'classnames';
import { formatDateTimeWithLocale } from '@/utility/localization';

const CalendarDayBox = (props) => {
  const { date, dark } = props;

  const { month, day } = useMemo(() => {
    let startDate;

    const isDateObj = date instanceof Date;
    const isDateInStr = typeof date === 'string';

    if (isDateObj || (isDateInStr && date?.includes('T'))) {
      startDate = new Date(date);
    } else {
      // only needed if date is a string in format yyyy-MM-dd
      startDate = parse(
        isValid(date) ? date : new Date().toISOString(),
        'yyyy-MM-dd',
        new Date()
      );
    }

    // Check if 'date' is a valid date
    if (!isValid(startDate)) {
      return {
        day: formatDateTimeWithLocale(new Date(), 'dd'),
        month: formatDateTimeWithLocale(
          new Date(),
          'MMM'
        ).toLocaleUpperCase()
      };
    }

    return {
      day: formatDateTimeWithLocale(startDate, 'dd'),
      month: formatDateTimeWithLocale(startDate, 'MMM').toLocaleUpperCase()
    };
  }, [date]);

  return (
    <div className="h-40 w-40 flex-shrink-0 overflow-hidden rounded-8 border-1 border-npl-neutral-light-solid-6 text-center">
      <div
        className={classNames({
          'bg-npl-neutral-light-solid-6': !dark,
          'bg-npl-yellow-dark-solid-6': dark
        })}>
        <p
          className={classNames('text-label-xs font-medium', {
            'text-dark-1b text-opacity-65': !dark,
            'text-npl-text-icon-on-dark-primary': dark
          })}>
          {month}
        </p>
      </div>
      <div className="py-4">
        <p className="text-label-md font-medium text-dark-1b">{day}</p>
      </div>
    </div>
  );
};

export default CalendarDayBox;
