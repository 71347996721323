import CustomCarousel from '@/components/features/CustomCarousel/CustomCarousel';
import { t } from '@/utility/localization';
import React, { useState } from 'react';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import FeedsCard from '@/features/community/components/FeedsCard';
import usePosts from '@/hooks/usePosts';
import { trackCommPublicPagePostSeeMoreClick } from '@/features/Feed/analytics';
// import SignUpPopup from '@/features/SignUpPopup';
import { SIGNUP_ORIGIN } from '@/features/SignUpPopup/constants';
import {
  getCommunityCheckoutRoute,
  getEntityPublicPageUrl,
  getMemberCommunityPageRoute
} from '@/utility/routesHelper';
import { useRouter } from 'next/navigation';
import AboutCommunity from '../components/AboutCommunity';
import useCommunityEntity from '../hooks/useCommunityEntity';
import EntityCardSkeletion from '../components/skeletons/EntityCardSkeletion';
import MobileCTAFooter from '../components/MobileCTAFooter';
import { ENTITY_SLUGS_PREFIX } from '@/utility/helpers';
import { showSuccessToast } from '@/components/common/ToastContainer';
import FeedSkeleton from '../components/skeletons/FeedSkeleton';
import { formatPostsData } from '@/pages/member/feed/utils';
import { getStaticPost } from '@/pages/member/feed/constants';
import useLocale from '@/hooks/useLocale';
import CommunitySignupModal from '@/features/CommunitySignupModal/CommunitySignupModal';
import JoinWhatsappChatCard from '../components/JoinWhatsappChatCard';
import EntityCard from '../components/EntityCard';
import { ENTITY_STATUS } from '../constants';

function HomeTab({
  communityPublicPageData,
  discountPercentageForHighestInterval,
  hasScrolledPastBanner,
  setIsSignUpModalOpened,
  genericTrackingFields,
  isLoggedInVariant, // mainly to hide about description without flickering
  commonPublicPageData,
  mutateCommunityData
}) {
  const { currentLocale } = useLocale();
  const router = useRouter();
  const { _id: communityId, slug: communityLink } =
    communityPublicPageData || {};

  const [postUsedToSignup, setPostUsedToSignup] = useState(null);
  const showPostSignupModal = !!postUsedToSignup;

  const {
    isCommunityMember,
    isCommunityAdmin,
    isPendingApproval,
    isUserCommunitiesFetched,
    // signup popup
    // signUpPopupRef,
    discountCodeFromQueryParams,
    pricingData,
    priceTagText,
    mutateUserCommunities
  } = commonPublicPageData;

  const isApplicationBasedComm =
    communityPublicPageData?.request_approval ||
    communityPublicPageData?.applicationConfig?.requestApproval;

  const isCommunityCheckoutRequired =
    isApplicationBasedComm || communityPublicPageData?.isPaidCommunity;

  const { link: platformLink } = communityPublicPageData?.platform || {};

  const { upcomingEntities, ongoingEntities, isFetchingEntities } =
    useCommunityEntity(communityId);

  const {
    allPosts,
    paginationMetadata: postsPaginationMetadata,
    getNextPage: getPostsNextPage,
    loading: loadingPosts,
    loadingMorePosts
  } = usePosts({
    communityId,
    pageSize: 3
  });

  const currentPosts =
    allPosts?.length > 0
      ? allPosts
      : !loadingPosts
        ? formatPostsData([
            getStaticPost(communityPublicPageData, currentLocale)
          ])
        : [];

  const { isGtEqMd } = useWindowWidthContext();

  const showSeeMoreCta = !!postsPaginationMetadata?.next;

  const goToCommunityCheckout = () => {
    const checkoutRoute = getCommunityCheckoutRoute({
      communityId: communityPublicPageData?._id,
      communityCode: communityPublicPageData?.code,
      discountCode: discountCodeFromQueryParams
    });

    router.push(checkoutRoute);
  };

  const onUnlockPostClick = (post) => {
    // For paid community or appln gated, go to checkout
    if (isCommunityCheckoutRequired) {
      goToCommunityCheckout();
      return;
    }

    // else open signup modal i.e for FREE Signup
    setPostUsedToSignup(post);
  };

  const closePostSignupModal = () => setPostUsedToSignup(null);

  const onSignViaPostSuccess = (isNewMember) => {
    if (isNewMember) {
      showSuccessToast(
        t('success-you-are-a-member-of-community', {
          communityName: communityPublicPageData?.title
        })
      );
    }

    mutateUserCommunities();
    mutateCommunityData();

    if (postUsedToSignup?.isStaticPost) {
      router.push({
        pathname: getMemberCommunityPageRoute(
          communityPublicPageData.slug
        ),
        query: {
          showSignupSuccessPrompt: 1
        }
      });
      return;
    }

    // redirect to single post page.
    const redirectUrl = getEntityPublicPageUrl({
      communitySlug: communityLink,
      entityType: ENTITY_SLUGS_PREFIX.POSTS,
      entitySlug: postUsedToSignup?.slug
    });

    router.push(redirectUrl);
  };

  // Must wait for userCommunities to be fetched, so that the states do not flicker
  // between locked and unlocked post view
  const hasPostAndUserRolesLoaded =
    loadingPosts || !isUserCommunitiesFetched;

  return (
    <div>
      <div className="c-PublicPageHomeTab flex w-full flex-col gap-32 rounded-b-12 py-32">
        {/* About Section */}
        {!isLoggedInVariant && communityPublicPageData?.description && (
          <>
            <div className="px-24">
              <div>
                <AboutCommunity
                  communityPublicPageData={communityPublicPageData}
                />
              </div>
            </div>
            {/* Divider */}
            <div className="mx-24 h-[1px] bg-npl-neutral-light-solid-4"></div>
          </>
        )}

        {/* Dismissable WA Chat Card */}
        <JoinWhatsappChatCard
          isCommunityMember={isCommunityMember}
          isWhatsappExperienceCommunity={
            communityPublicPageData?.isWhatsappExperienceCommunity
          }
          waGroupChatLink={platformLink}
        />

        {isFetchingEntities && (
          <div className="px-16 md:px-24">
            <div className="mb-16 h-40 w-240 animate-pulse rounded-8 bg-npl-neutral-light-solid-4"></div>
            <div className="flex gap-16">
              <EntityCardSkeletion />
              <EntityCardSkeletion />
            </div>
          </div>
        )}

        {/* Ongoing Section */}
        {ongoingEntities?.length > 0 && (
          <>
            <div className="ml-16 md:ml-0 md:px-24">
              <CustomCarousel
                autoplay={false}
                title={t('ongoing')}
                showArrowButtons={false}
                showTopRightArrowButtons={
                  isGtEqMd && ongoingEntities?.length > 2
                }
                showDotButtons={ongoingEntities?.length > 2}
                customOptions={{
                  loop: false,
                  align: 'start',
                  slidesToScroll: isGtEqMd ? 2 : 1
                }}>
                {ongoingEntities.map((ongoingEntity, index) => (
                  <div
                    className="mb-16 flex w-[320px] flex-shrink-0 pr-16 md:w-[51%]"
                    key={index}>
                    <EntityCard
                      entity={ongoingEntity}
                      communityLink={communityLink}
                      genericTrackingFields={genericTrackingFields}
                      communityConfig={communityPublicPageData?.config}
                      entityStatus={ENTITY_STATUS.ONGOING}
                    />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </>
        )}

        {/* Upcoming Section */}
        {upcomingEntities?.length > 0 && (
          <>
            <div className="ml-16 md:ml-0 md:px-24">
              <CustomCarousel
                autoplay={false}
                title={t('upcoming')}
                showArrowButtons={false}
                showTopRightArrowButtons={
                  isGtEqMd && upcomingEntities?.length > 2
                }
                showDotButtons={upcomingEntities?.length > 2}
                customOptions={{
                  loop: false,
                  align: 'start',
                  slidesToScroll: isGtEqMd ? 2 : 1
                }}>
                {upcomingEntities.map((upcomingEntity, index) => (
                  <div
                    className="flex w-[320px] flex-shrink-0 pr-16 md:w-[51%]"
                    key={index}>
                    <EntityCard
                      entity={upcomingEntity}
                      communityLink={communityLink}
                      genericTrackingFields={genericTrackingFields}
                      entityStatus={ENTITY_STATUS.ONGOING}
                    />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </>
        )}
        {/* Divider */}
        {(ongoingEntities?.length > 0 || upcomingEntities?.length > 0) && (
          <div className="mx-24 h-[1px] bg-npl-neutral-light-solid-4"></div>
        )}

        {/* Posts / Feed */}
        {hasPostAndUserRolesLoaded ? (
          <div className="mt-64 px-16 md:px-24">
            <FeedSkeleton />
          </div>
        ) : (
          currentPosts.length > 0 && (
            <div className="px-16 md:px-24">
              <div className="mb-16 text-heading-lg font-semibold">
                {t('feed')}
              </div>
              <div>
                <FeedsCard
                  communityData={communityPublicPageData}
                  posts={currentPosts}
                  showSeeMoreCta={showSeeMoreCta}
                  onSeeMoreClick={() => {
                    getPostsNextPage();
                    trackCommPublicPagePostSeeMoreClick();
                  }}
                  onJoinCommunityClick={onUnlockPostClick}
                  isCommunityAdmin={isCommunityAdmin}
                  isCommunityMember={isCommunityMember}
                  isPendingApproval={isPendingApproval}
                  loadingMorePosts={loadingMorePosts}
                />
              </div>
            </div>
          )
        )}
        {hasScrolledPastBanner && (
          <MobileCTAFooter
            priceTagText={priceTagText}
            setIsSignUpModalOpened={setIsSignUpModalOpened}
            discountPercentageForHighestInterval={
              discountPercentageForHighestInterval
            }
            isPendingApproval={isPendingApproval}
          />
        )}
        {/* Modals */}
        {/* On Signup via locked post */}
        <CommunitySignupModal
          open={showPostSignupModal}
          onClose={closePostSignupModal}
          communityInfo={{
            ...communityPublicPageData,
            pricingData
          }}
          priceTagText={priceTagText}
          discountCodeFromQueryParams={discountCodeFromQueryParams}
          signUpOriginProps={{
            signUpOrigin: SIGNUP_ORIGIN.POST_PAGE,
            postInfo: postUsedToSignup
          }}
          newMemberSignupCallback={() => onSignViaPostSuccess(true)}
          existingMemberLoginCallback={onSignViaPostSuccess}
        />
      </div>
    </div>
  );
}

export default HomeTab;
