import CommunityPageLayout from '@/components/features/CommunityPage/layouts/CommunityPageLayout';
// import { fetchCommunityDataBySlug } from '@/services/communitiesService';
import React from 'react';
import HomeTab from '../../components/features/CommunityPage/tabContents/HomeTab';
import { getCommunityStaticProps } from './utils';
import useTrackTabPageView from '@/components/features/CommunityPage/hooks/useTrackTabPageView';
import { TAB_PAGE_VIEW_EVENT_NAME } from '@/components/features/CommunityPage/constants';
import PageMetaTags from '@/components/common/PageMetaTags';
import { checkIfIndexable } from '@/utility/helpers';
import config from '@/utility/config';

const CommunityPage = ({
  communityPublicPageData,
  discountPercentageForHighestInterval,
  hasScrolledPastBanner,
  commonPublicPageData,
  genericTrackingFields,
  mutateCommunityData
}) => {
  const { setIsSignUpModalOpened } = commonPublicPageData;

  useTrackTabPageView({
    eventName: TAB_PAGE_VIEW_EVENT_NAME.HOME,
    communityId: communityPublicPageData?._id
  });

  const communityLink = communityPublicPageData?.slug;

  return (
    <div>
      {/* Meta tags for SEO */}
      <PageMetaTags
        {...communityPublicPageData?.metadata}
        canonicalUrl={`${config.siteBaseUrl}${communityLink}`}
        description={communityPublicPageData?.description}
        indexable={checkIfIndexable(communityPublicPageData)}
      />
      <HomeTab
        communityPublicPageData={communityPublicPageData}
        isCommunityMember={false}
        isPendingApproval={false}
        discountPercentageForHighestInterval={
          discountPercentageForHighestInterval
        }
        hasScrolledPastBanner={hasScrolledPastBanner}
        setIsSignUpModalOpened={setIsSignUpModalOpened}
        genericTrackingFields={genericTrackingFields}
        commonPublicPageData={commonPublicPageData}
        mutateCommunityData={mutateCommunityData}
      />
    </div>
  );
};

CommunityPage.getLayout = function getLayout(page) {
  const communityPublicPageData = page.props.communityPublicPageData;
  return (
    <CommunityPageLayout
      // communityData={communityData}
      communityPublicPageData={communityPublicPageData}
      showBanner>
      {page}
    </CommunityPageLayout>
  );
};

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking'
  };
}

export const getStaticProps = getCommunityStaticProps;
export default CommunityPage;
